import request from '@/utils/request'

export default {
  getCompanyInfo (params) {
    return request({
      url: '/company/list',
      method: 'get',
      params: params
    })
  },
  getCompanyById (id) {
    return request({
      url: '/company/detail',
      method: 'get',
      params: id
    })
  },
  addCompany (data) {
    return request({
      url: '/company/add',
      method: 'POST',
      data: data
    })
  },
  updateCompany (data) {
    return request({
      url: '/company/modify',
      method: 'POST',
      data: data
    })
  },
  handlerCompany (data) {
    return request({
      url: '/company/updateStatus',
      method: 'POST',
      data: data
    })
  }
}
