<template>
  <el-container>
    <el-header>
      <el-input
        v-model="queryInfo.companyName"
        @blur="getCompanyInfo"
        placeholder="搜索公司名称"
        prefix-icon="el-icon-search"
      />
      <el-button
        type="primary"
        style="margin-left: 5px"
        icon="el-icon-plus"
        @click="showAddDialog('')"
      >
        添加
      </el-button>
    </el-header>

    <el-main>
      <!--操作的下拉框-->
      <el-select
        @change="selectChange"
        clearable
        v-if="selectedInTable.length !== 0"
        v-model="selected"
        :placeholder="'已选择' + selectedInTable.length + '项'"
        style="margin-bottom: 25px;"
      >
        <el-option
          v-for="(item,index) in optionInfo"
          :key="index"
          :value="item.desc"
        >
          <span style="float: left">{{ item.label }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
        </el-option>
      </el-select>

      <el-table
        ref="multipleTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="companyInfo"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        />

        <el-table-column
          align="center"
          prop="companyName"
          label="公司名称"
        />

        <el-table-column
          align="center"
          prop="address"
          label="公司地址"
        />

        <el-table-column
          align="center"
          prop="email"
          label="公司邮箱"
        />

        <el-table-column
          align="center"
          label="创建时间"
        >
          <template v-slot="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="状态"
        >
          <template v-slot="scope">
            {{ scope.row.enable === 1 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
        >
          <template v-slot="scope">
            <el-button type="text" size="small"  @click="showAddDialog(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        style="margin-top: 25px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </el-main>

    <el-dialog
      title="更新公司信息"
      :visible.sync="addTableVisible"
      width="60%"
      @close="resetAddForm"
      center
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-input v-model="addForm.id" type="hidden" />
        <el-form-item
          label="公司名称"
          label-width="120px"
          prop="companyName"
        >
          <el-input v-model="addForm.companyName" />
        </el-form-item>

        <el-form-item
          label="公司地址"
          label-width="120px"
          prop="address"
        >
          <el-input
            v-model="addForm.address"
          />
        </el-form-item>

        <el-form-item
          label="邮箱"
          label-width="120px"
          prop="email"
        >
          <el-input
            v-model="addForm.email"
          />
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeAddDialog">
          取 消
        </el-button>

        <template  v-if="this.formOpt==='add'">
        <el-button
          type="primary"
          @click="addCompany"
        >
            新增
        </el-button>
        </template>
        <template  v-if="this.formOpt==='update'">
        <el-button
          type="primary"
          @click="updateCompany"
        >
          修改
        </el-button>
        </template>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import utils from '@/utils/utils'
import company from '@/api/company'

export default {
  name: 'CompanyManage',
  data () {
    // 自定义用户名校验规则
    var validateCompanyName = (rule, value, callback) => {
      this.$http.get(this.API.checkUsername + '/' + this.addForm.username).then((resp) => {
        if (resp.data.data) {
          callback()
        } else {
          callback(new Error('用户名已存在'))
        }
      })
    }
    return {
      // 查询用户的参数
      queryInfo: {
        companyName: '',
        pageNo: 1,
        pageSize: 10
      },
      // 公司信息
      companyInfo: [],
      // 下拉选择框的数据
      optionInfo: [
        {
          label: '启用',
          desc: 'on'
        },
        {
          label: '禁用',
          desc: 'off'
        },
        {
          label: '删除',
          desc: 'delete'
        }
      ],
      // 下拉框所选择的数据
      selected: '',
      // 被选择的表格中的行数据
      selectedInTable: [],
      // 所有用户的条数
      total: 0,
      // 添加用户的对话框是否显示
      addTableVisible: false,
      formOpt:'add',
      // 添加用户的表单信息
      addForm: {
        id:'',
        companyName: '',
        address: '',
        email: ''
      },
      // 添加用户表单的验证规则
      addFormRules: {
        companyName: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur'
          },
          {
            validator: validateCompanyName,
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: '公司邮箱',
            trigger: 'blur'
          }
        ]
      },
      // 表格信息加载
      loading: true
    }
  },
  created () {
    this.getCompanyInfo()
  },
  methods: {
    // 获取用户信息
    getCompanyInfo () {
      company.getCompanyInfo(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          this.companyInfo = resp.data.data
          this.total = resp.data.total
          this.loading = false
        } else {
          this.$notify({
            title: 'Tips',
            message: '获取信息失败',
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 表格某一行被选中
    handleSelectionChange (val) {
      this.selectedInTable = val
    },
    // 功能下拉框被选择
    selectChange (val) {
      // 清空上一次的操作
      this.selected = ''
      // 表格中所选中的用户的id
      const comanyIds = []
      this.selectedInTable.map(item => {
        comanyIds.push(item.id)
      })
      if (val === 'on') { // 状态设置为正常
        company.handlerCompany({ status:1,ids: comanyIds.join(',') }).then((resp) => {
          if (resp.code === 200) {
            // 删除成功后,回调更新用户数据
            this.getc()
            this.$notify({
              title: 'Tips',
              message: '操作成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: '操作失败',
              type: 'error',
              duration: 2000
            })
          }
        })
      } else if (val === 'off') { // 禁用用户
        company.handlerCompany({ status:2,ids: comanyIds.join(',') }).then((resp) => {
          if (resp.code === 200) {
            // 删除成功后,回调更新用户数据
            this.getUserInfo()
            this.$notify({
              title: 'Tips',
              message: '操作成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: '操作失败',
              type: 'error',
              duration: 2000
            })
          }
        })
      } else if (val === 'delete') { // 删除用户
        company.handlerCompany( { status:3,ids: comanyIds }).then((resp) => {
          if (resp.code === 200) {
            // 删除成功后,回调更新用户数据
            this.getCompanyInfo()
            this.$notify({
              title: 'Tips',
              message: '操作成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: '操作失败',
              type: 'error',
              duration: 2000
            })
          }
        })
      }
    },
    // 分页插件的大小改变
    handleSizeChange (val) {
      this.queryInfo.pageSize = val
      this.getCompanyInfo()
    },
    // 分页插件的页数
    handleCurrentChange (val) {
      this.queryInfo.pageNo = val
      this.getCompanyInfo()
    },
    // 点击添加按钮
    showAddDialog (id) {
      this.formOpt='add'
      if(typeof id !== 'undefined' && id != null && id !== '') {
        company.getCompanyById({id:id}).then((resp) => {
          console.log(resp)
          this.addForm.companyName = resp.data.companyName
          this.addForm.address =  resp.data.address
          this.addForm.email =  resp.data.email
          this.addForm.id =  resp.data.id
          this.formOpt='update'
        });
      }
      this.addTableVisible = true

    },
    closeAddDialog(){
      // 清空表格数据
      this.$refs.addForm.resetFields()
      this.addTableVisible = false
    },

    updateCompany(){
      utils.validFormAndInvoke(this.$refs.addForm, () => {
        company.updateCompany(this.addForm).then((resp) => {
          if (resp.code === 200) {
            this.getCompanyInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'error',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请检查您所填写的信息是否有误')
    },
    // 新增公司
    addCompany () {
      utils.validFormAndInvoke(this.$refs.addForm, () => {
        company.addCompany(this.addForm).then((resp) => {
          if (resp.code === 200) {
            this.getCompanyInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'error',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请检查您所填写的信息是否有误')
    },
    // 表单信息重置
    resetAddForm () {
      // 清空表格数据
      this.$refs.addForm.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/admin/userManage";
</style>
